import {
  getCampaigns,
  registerCampaign,
  findCampaign,
  countCampaigns,
  createCampaign,
  updateCampaign,
  copyCampaign
} from '@/services/campaign.service'
import { convertDate } from '@/helpers/filters'
import { getCreativesGroups, createCreativeGroup,updateCreativeGroup,createCreative, updateCreative, deleteCreative } from '@/services/creativesGroups.service'
import { getPurchaseTypes } from '@/services/purchaseType.service'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    datetime_campaign: "",
    countCampaigns: 0,
    purchaseTypes: [],
    creativeGroups: [],
    campaigns: [],
    campaign: null,
    newcampaign: null,
    isLoading: false,
    error: null,
    variables: {
      orderby: 'campaign_id',
      direction: 'desc',
      offset: 0,
      limit: 50
    }
  },
  getters: {
    campaigns: (state) => state.campaigns,
    purchaseTypes: (state) => state.purchaseTypes,
    creativeGroups: (state) => state.creativeGroups.sort((a, b) => a.creative_group_id - b.creative_group_id),
    countCampaigns: (state) => state.countCampaigns,
    campaign: (state) => {
      if(state.campaign?.campaign_created_at){
        state.campaign.campaign_created_at = convertDate(state.campaign.campaign_created_at)
      }
       return state.campaign
    },
    newcampaign: (state) => {
      return state.newcampaign
    },
    variables: (state) => state.variables,
    isLoading: (state) => state.isLoading,
    error: (state) => state.error
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.isLoading = loading
    },
    SET_CAMPAIGN(state, payload) {
      state.campaign = {...payload}
    },
    UPDATE_CAMPAIGN(state, payload) {
      if(payload?.campaign_created_at){
        payload.campaign_created_at = moment(payload.campaign_created_at).format('DD/MM/YYYY')
      }
      state.campaign = {...payload}
    },
    SET_CREATIVE_GROUPS(state, payload) {
      state.creativeGroups = payload
    },
    SET_PURCHASE_TYPES(state, payload) {
      state.purchaseTypes = payload
    },
    SET_COUNT_CAMPAIGN(state, payload) {
      state.countCampaigns = payload
    },

    SET_VARIABLE_CAMPAIGNS(state, payload) {
      state.variables = { ...payload }
    },

    UPDATE_CAMPAIGNS(state, payload) {
      const index = state.campaigns.findIndex((task) => task.id === payload.id)
      if (index !== -1) {
        state.campaigns[index] = payload
      }
    },

    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload
    },

    SET_NEW_CAMPAIGN(state, payload) {
      state.newcampaign = payload
    },

    CREATE_CAMPAIGNS(state, payload) {
      state.campaigns.push(payload)
    },

    UPDATE_ERROR(state, error) {
      state.error = error
    },
    CLEAR_CAMPAIGN(state, payload) {
      state.campaign = payload
    }

  },
  actions: {
    async changeStatusStore({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        await updateCampaign(data)
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async updateCampaign({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        const res = await updateCampaign(data)
        commit('UPDATE_CAMPAIGN', res)
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async get({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        const response = await getCampaigns(data)
        commit('SET_CAMPAIGNS', response)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async getCreativesGroups({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        const response = await getCreativesGroups(data)
        const responseFormat = response[0].creatives_groups.map(el=>{
          return {
            ...el,
            edit: false
          }
        })
        commit('SET_CREATIVE_GROUPS', responseFormat)
        return responseFormat
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async createCreative({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        await createCreative(data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async createCreativeGroup({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        await createCreativeGroup(data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async updateCreativeGroup({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        await updateCreativeGroup(data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async updateCreative({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        await updateCreative(data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async deleteCreative({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        await deleteCreative(data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async getPurchaseTypes({ commit }, data) {
      commit('SET_LOADING', true)
      try {
        const response = await getPurchaseTypes(data)
        commit('SET_PURCHASE_TYPES', response)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async getCountCampaigns({ commit }, data) {
      const res = await countCampaigns()
      commit('SET_COUNT_CAMPAIGN', res)
    },

    async store({ commit }, data) {
      const res = await registerAgency(data)

      commit('CREATE_AGENCYS', res)
      commit('SET_AGENCY', res)
      return res
    },

    async update({ commit }, data) {
      const res = await updateAgency(data)

      commit('UPDATE_AGENCY', res)
      return res
    },
    
    async find({ commit, dispatch }, data) {
      const res = await findCampaign(data)
      
      var emails = []
      if (res?.campaign_emails !== '' && res?.campaign_emails != null) {
        emails = res?.campaign_emails.split(',')
      }
      const emailMap = emails.map((el) => {
        return {
          contact_name: null,
          contact_email: el,
          contact_groups_ids: null
        }
      })
      
      var campaignContact = res.account.accounts_contacts
      var groupsContact = res.account.accounts_contacts_groups
      var campaigAdvertiserContact = res.advertiser.accounts_contacts
      var groupsAdvertiserContact = res.advertiser.accounts_contacts_groups
      const combinedContacts = [...campaignContact, ...campaigAdvertiserContact, ...emailMap]

      // Filtra contatos para remover duplicados com a condição especificada e elimina contatos vazios
      const uniqueContacts = combinedContacts.filter((contact, index, self) => {
        return (
          contact.contact_email && // Verifica se o email não é vazio
          (self.findIndex(
            (c) =>
              c.contact_email === contact.contact_email &&
              (c.contact_groups_ids || c.contact_name)
          ) === index ||
            (contact.contact_groups_ids == null && contact.contact_name == null))
        )
      })

      campaignContact = uniqueContacts
      dispatch('contact/setContacts', campaignContact, { root: true })
      dispatch('contact/setGroupsContacts', [...groupsAdvertiserContact,...groupsContact], { root: true })
      commit('SET_CAMPAIGN', res)
    },

    async copyCampaign({ commit }, data) {
      const res = await copyCampaign(data)
      
      commit('SET_NEW_CAMPAIGN', res)
    },

    async setVariable({ commit }, data) {
      commit('SET_VARIABLE_CAMPAIGNS', data)
    },

    async createCampaignStore({ commit }, data) {
      console.log(data);
      try {
        const response = await createCampaign(data)
        commit('CREATE_CAMPAIGNS', response.data)
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
    async clearCampaign({ commit }) {
        commit('CLEAR_CAMPAIGN', null)
    }
  }
}
